$blue: rgb(81, 199, 241);

.mint-section-btn-wrapper {
    display: flex;
    width: 470px;
    justify-content: space-between;
    margin-top: 1.8rem;
}

#mint-section-connect-btn-1 {
    background-color:$blue white;

    &:hover {
        background-color: white;
    }
}

.mint-section-connect-btn {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 0.9rem;
    color: black;
    width: 220px;
    height: 50px;
    background-color: $blue;
    border-radius: 10px;
    background-color: $blue;

    &:hover {
        background-color: white;
    }

    box-shadow: 0px 0px 5px black;
}
.mint-btn{
    width: 250px;
}
.mint-section-amount-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    margin: 1.5rem 0 0.5rem 0;
    button {
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        font-size: 1.4rem;
        color: black;
        width: 50px;
        height: 50px;
        background-color: $blue;
        border-radius: 10px;

        &:hover {
            background-color: white;
        }

        box-shadow: 0px 0px 5px black;
    }
}
.mint-section-time-wrapper{
    display: flex;
    justify-content: space-between;
    width: 300px;
    // background-color: red;
}
.mint-section-time-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 70px;
    height: 70px;
    background-color: $blue;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Lato',sans-serif;
    line-height: 0px;
    color:black;
    box-shadow: 0px 0px 5px black;

    span{
        position: absolute;
        bottom:13px;
        font-size: 12px;
        font-weight: 400;

    }
}
.mint-price{
    // background-color: red;
    position: relative;
   
    width:250px;
    margin-top: 0.5rem;
    text-align: center;
}
.mint-price-connect{
    width:220px;
    margin-top: 0.5rem;
    text-align: center;
}
@media screen and (max-width:525px) {
    .mint-section-btn-wrapper {
        width: 90vw;
    }

    .mint-section-btn-wrapper {
        margin-top: 0.5rem;
        flex-direction: column;
    }

    .mint-section-connect-btn {

        width: 100%;
        height: 42px;
        margin-top: 0.5rem;
    }
    .mint-section-amount-wrapper {
        width: 90vw;
        margin: 1rem 0 0.5rem 0;
    }
    .mint-btn{
        height: 45px;
    }
    .mint-price,.mint-price-connect{
       
        width:90vw;
        margin: 0.8rem 0 0.3rem  0;
    }
}