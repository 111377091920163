.team-section{
    position: relative;
    width: 100vw;
    margin-top: 8rem;
    z-index: 5  ;
   
}
.team-title{
    width: fit-content;
    margin: auto;
    text-align: center;
    max-width: 90vw;
}
.team-sub-title{
    width: fit-content;
    margin: auto;
    text-align: center;
    max-width: 90vw;
    margin-top: 2rem;
}
.team-section-inner{
    display: flex;
    margin: auto;
    justify-content: space-between;
    width: 1155px;
    max-width: 95vw;
    margin-top: 4rem;
    flex-wrap: wrap;
    // background-color: red;
}
.team-wrapper{
    transform: scale3d(0.5,0.5,1);
    opacity: 0;
    position: relative;
    top:300px;

    position: relative;
    background-color:rgba(0,0,0,0.4);
    width: 260px;
    flex-shrink: 0;
    height: 426px;
    border-radius: 13px;
    // overflow: hidden;
    .team-pfp{
        width: 100%;
        border-radius: 1rem 1rem 0 0;
    }
    
}
.team-social-btn{
    position: absolute;
    bottom:-50px;
    left:0;
    right:0;
    margin: auto;
    height: 30px;
    img{
        height: 30px;
    }
}

#team-1{
    transition: top 700ms ease, opacity 700ms ease,transform 700ms ease;
}
#team-2{
    transition: top 900ms ease, opacity 900ms ease,transform 900ms ease;
}
#team-3{
    transition: top 1.1s ease, opacity 1.1s ease,transform 1.1s ease;
}
#team-4{
    // transition: top 1.3s ease, opacity 1.3s ease,transform 1.3s ease;
    transition: top 1.1s ease, opacity 1.1s ease,transform 1.1s ease;

}

.team-name{
    margin: 1rem 0 0.5rem
}
.team-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.team-personal-profile{
    position: absolute;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    top:-20px;
    left:-20px;
    background-color: black;
    border: 2px solid white;
}

@media screen  and (max-width:1160px){
    .team-section-inner{
        margin-top: 0rem;
        width: 600px;
        max-width: 90vw;
    }
    .team-wrapper{
        margin-top: 4rem;
        
    }
}

@media screen  and (max-width:620px){
    .team-section-inner{
        flex-direction: column;
        align-items: center;
    }
    .team-wrapper{
        width: 260px;
    }
}

@media screen  and (max-width:525px){
    .team-section{
        margin-top: 4rem;
    }
    .team-wrapper{
        margin-top: 4.5rem;
        
    }
    .team-sub-title{
        margin-top: 1rem;
        margin-bottom: -2rem;
    }

    .team-social-btn{
    
        bottom:-35px;
       
    }

}