.wallet-modal{
//    height: 500px;
    width: 450px;
    background-color: black;
    border-radius: 15px;
    overflow: hidden;
    max-width: 90vw;
    // border: 1px solid white;
    box-shadow: 0px 0px 5px 1px white;
    background-color: rgba(0,0,0,0.8);

}
.modal-connect-button{
    cursor: pointer;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 80px;
    background-color: transparent;
    border:none;
    border-bottom: 1px solid rgb(150, 150, 150);
    img{
        width: 27px;
        margin-right: 20px;
    }
    &:hover{
        background-color: rgba(0,0,0,1);
    }
    span{
        font-family:'Times New Roman', Times, serif;
        font-weight: bold;
    }
}