.footer{
    position: relative;
    width: 100vw;
    height: 80px;
    background-color: #000000;
}
.footer-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 1155px;
    max-width: 90vw;
    margin: auto;
}
.footer-right{
    display: flex;
    justify-content: space-between;
    width: 275px;
}

@media screen and (max-width:850px) {
    .footer{
        height: fit-content;
    }
    .footer-inner{
        flex-direction: column;
        height: fit-content;
        text-align: center;
        padding: 2rem 0 2rem;
    }
    .footer-right{
        margin-top: 1rem;
    }
}