$blue:rgb(81, 199, 241);


.top-section{
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 5  ;

}
.top-section-inner{
    // background-color: red;
    width: 1155px;
    margin: auto;
    max-width: 90vw;
}

.top-section-bottom{
    display: flex;
    margin-top: 1rem;
}
.top-section-info-wrapper{
    // display: flex;
    // flex-direction: column;
    margin: 1.8rem 0 0 1.5rem;
    
}
.top-section-info{
    margin-top: 1.3rem;
    margin-bottom: 1.5rem;
    text-align: justify;
}
.top-section-encourage{
    margin-top: 0.5rem;
}
.top-section-logo{
    position: relative;
    left:-46px;
    width: 250px;
    visibility: hidden;
}
.top-section-img-wrapper{
    position: relative;
    flex-shrink: 0;
    height: 550px;
    width: 490px;
    // background-color: red;
    // img{
    //     position: absolute;
    //     animation: top-section-appear 300ms ease-in;
    //     -webkit-animation-fill-mode: forwards;
    //     animation-fill-mode: forwards;
    //     transition: all 450ms ease-in-out;
    //     border-radius: 12px;
    //     border: 2px solid white;
    // }
}
#top-section-img-1{
    height: 62%;
    left:50px;
    // opacity: 0;
    bottom:37.3%;
    // animation-delay:0.5s ;
    z-index: 0;
}
#top-section-img-2{
    height: 40%;
    left: 54.2%;
    bottom: 13.5%;
    // animation-delay:1.5s;
    // opacity: 0;
    z-index: 1;

}
#top-section-img-3{
    left:0;
    height: 43%;
    bottom: 2%;
    // animation-delay:1s;
    // opacity: 0;
    z-index: 1;

}
// @keyframes top-section-appear{
//     0%{
//         opacity: 0;
//     }
//     100%{
//         opacity: 1;
//     }
// }
// @keyframes top-section-appear{
//     0%{
//         opacity: 0;
//     }
//     100%{
//         opacity: 1;
//     }
// }




.top-sectin-social-wrapper{
    display: flex;
    margin-top: 1.8rem
}
.social-btn{
 
    margin-right: 1rem;
}



@media screen and (max-height:720px) and (min-width:1100px){
    .top-section-logo{
        left:-17px;
        width: 170px;
    }
    .top-section-img-wrapper{
        height: 460px;
        width: 409px;
    }
    .top-section-info-wrapper{
        margin: -1rem 0 0 1.5rem;
    }
}

@media screen  and (max-width:1130px){
    .top-section-img-wrapper{
        height: 480px;
        width: 422px;
    }
    .top-section-info-wrapper{
        margin: -1rem 0 0 1.5rem;
    }
    .top-section-logo{
        left:-17px;
        width: 200px;
    }
}

@media screen  and (max-width:1020px){
    .top-section{
        height: fit-content;
    }
    .top-section-inner{
        width: 705px;
    }
    .top-section-img-wrapper{
        height: 801px;
        width: 705px;
    }
    .top-section-bottom{
        flex-direction: column-reverse;
        // background-color: red;
    }
    .top-section-info-wrapper{
        margin: 2rem 0 3rem 0rem;
    }
    .top-section-logo{
        left:unset;
        width: 200px;
    }
    .top-section-info{
        margin-top: 1.5rem;
    }
}

@media screen  and (max-width:785px){
  
    .top-section-img-wrapper{
        height: calc(90vw / 0.879);
        width: 90vw;
    }

}
@media screen  and (max-width:525px){
    // .top-section-bottom{
    //     flex-direction: column;
    //     // background-color: red;
    // }
    .top-section-info-wrapper{
        margin: -1rem 0 3rem 0rem;
    }
    .top-section-logo{
        margin-top:-10px
    }
    .top-section-img-wrapper{
        display: none;
    }
    .top-section-info-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;

        //calculation for mobile height- logo height - logo margin top
        height: calc(100vh -  132px);
       
    }
    // .top-section-btn-wrapper{
    //     width: 90vw;
    // }
    // .top-section-connect-btn{
    //     width: 48%;
    //     height: 45px;
    // }
    .top-section-info{
        margin-top: 1rem;
    }
    .top-section-encourage{
        margin-top: 1rem;
    }
    // .top-section-btn-wrapper{
    //     margin-top: 0.5rem;
    //     flex-direction: column;
    // }
    // .top-section-connect-btn{
       
    //     width: 100%;
    //     height: 42px;
    //     margin-top: 0.5rem;
    // }
    .top-sectin-social-wrapper{
        margin-top: 1rem
    }
    .social-btn{
        margin-right: 0.7rem;
    }
}