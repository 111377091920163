body{
  background-color: black;
  
}
*{
  margin:0;
  padding:0;
   /* image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast; 
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; */
}
button{
  border:none;
  background: transparent;
  /* cursor: pointer; */
  cursor:pointer;
  outline: none;
}
