$shadow: 0px 0px 6px #333333;
$blue:rgb(81, 199, 241);

.faq-section{
    position: relative;
    z-index: 5;
    width: 100vw;
    text-align: center;
    margin-top: 7rem;

}
.faq-section-title{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:auto;
    cursor: pointer;
    background-color: rgba(0,0,0,0.4);
    width: 220px;
    height: 70px;
    border-radius: 13px;
    box-shadow: 0px 0px 5px 1px white;
    img{
        position: absolute;
        height: 33px;
        right: 14px;
        transition: all 300ms ease-in-out;
    }
}
.faq-section-inner-main{
    height:0px ;
    overflow:hidden;
    transition: height 300ms ease-in-out;
    // background-color: red;
}
.faq-section-inner{
    margin: auto;
    margin-top: 2rem;
    width: 900px;
    max-width: 90vw;
    text-align: start;
    // background-color: rebeccapurple;
    height: fit-content;
    // text-shadow:1px 1px 1px #333333; 
}
.faq{
    height: 85px;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    // border-radius: 13px;
    border-bottom:1px solid white;
    // margin-top: 1rem;
    transition: height 250ms ease-in-out,border 250ms ease-in-out,background-color 250ms ease-in-out;
    // background-color: rgba(0,0,0,0.4);
    .faq-answer-inner{
        margin:5px 17px 22px 17px;
        text-align: justify;
        a,button{
            color:white;
            text-decoration: underline;
        }
    }
    // &:hover{
    //     background-color:rgba(0,0,0,0.6) ;
    //     border:1px solid $blue;
    // }
}
.faq-question{
    position: relative;
    cursor: pointer;
    // text-align: center;
    display: flex;
    // justify-content:flex-start;
    align-items: center;
    // background-color: rebeccapurple;
    height: 85px;
    width: 100%;
  
    .faq-question-title {
        max-width: 90%;
        // background-color: red;
    }

}
.faq-answer{
    // width: fit-content;
    height: fit-content;
    margin: auto;
}

.faq-expand-btn {
    position: absolute;
    right: -8px;
    img{
        height: 33px;
    }
}

@media screen and (max-width:500px) {
    .faq-section{
        margin-top: 5rem;
    }
    // .faq-section-inner{
    //     // margin-bottom: 15rem;
    //     // background-color: red;
    // }
    // .faq{
       
    //     margin-top: 1.5rem;     
    // }
    .faq-section-inner{
        margin-top: 1rem;
    }
}