.roadmap{
    width: 100vw;
    position: relative;
    margin-top: 4rem;
    padding: 3rem 0 3rem 0;
    z-index: 5  ;
    background: transparent linear-gradient(180deg,rgba(31,76,149,.78) 0%,rgba(13,37,77,.78) 100%) 0% 0% no-repeat padding-box;
}
.roadmap-title{
    width: fit-content;
    margin: auto;
    margin-top: 8rem;
}
.roadmap-sub-title{
    width: fit-content;
    margin: 0 auto 2rem auto;
}

.roadmap-sub-title-2{
    width: fit-content;
    margin: 8rem auto 2rem auto;
}
.roadmap-inner{
    width: 1155px;
    max-width: 90vw;
    margin: auto;
    margin-top: 3rem;
}
.roadmap-top{
    position: relative;
    width: 100%;
    perspective: 1100px;
}
.roadmap-app-img{
    position: absolute;
    width: 270px;
    bottom:-50px;
    right: 80px;
    transform: scale3d(0.1,0.1,1);
    z-index: 2;
    transition: all 300ms ease-in-out;

}
.roadmap-goal{
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 5px 1px white;
    margin-bottom: 1.2rem;
    border-radius: 7px;
    padding: 8px;
    background-color: rgba(0,0,0,0.4);
    width: fit-content;
    text-align: justify;
    max-width: 90vw;
    opacity: 0.1;
    transition: all 300ms ease-in-out;
    top:100px;
}

.roadmap-bottom{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.roadmap-bottom-header{
    height: 70px;
    // width: ;
}
.roadmap-bottom-phase{
    transform: scale3d(0.5,0.5,1);
    opacity: 0;
    position: relative;
    top:300px;
    display: flex;
    flex-direction: column;
    
    padding: 10px;
    box-sizing: border-box;
    width: 280px;
    height: 345px;
    background-color: rgba(0,0,0,0.4);
    border-radius: 12px;
    box-shadow: 0px 0px 5px 1px white;

}
.roadmap-bottom-phase-header{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
}

#roadmap-bottom-phase-1{
    transition: top 700ms ease, opacity 700ms ease,transform 700ms ease;
}
#roadmap-bottom-phase-2{
    transition: top 900ms ease, opacity 900ms ease,transform 900ms ease;
}
#roadmap-bottom-phase-3{
    transition: top 1.1s ease, opacity 1.1s ease,transform 1.1s ease;
}
#roadmap-bottom-phase-4{
    transition: top 1.3s ease, opacity 1.3s ease,transform 1.3s ease;
}

@media screen and (max-width:1245px){
    .roadmap-bottom-phase{
        margin-top: 2rem;
    }
}
@media screen and (max-width:1200px){
    
    .roadmap-app-img{
        width: 210px;
        bottom:0px;
        right:20px;
    }
  
    .roadmap-bottom-text{
        width: 490px;
        .roadmap-goal{
            width: 490px;
        }
    }
    .roadmap-bottom-phase{
        margin-bottom: 2rem;
    }
}

@media screen and (max-width:1090px){
    .roadmap-top{
        display: flex;
        flex-direction: column-reverse;
    
    }
    .roadmap-app-img{
        position: unset;
        margin: auto;
        width: 250px;
        transform: unset;
    }
    .roadmap-top-text{
        width: fit-content;
        margin: 2rem auto 0 auto;
    }
    .roadmap-bottom{
        flex-direction: column;
        align-items: center;
    }
    .roadmap-bottom-img{
        width: 350px;
        height: 500px;
        margin:0 0 2rem 0;
    }
    .roadmap-bottom-text{
        width: 490px;
    }
    .roadmap-goal{
        width: 490px;
        margin-bottom: 0.6rem;
        padding: 10px;
    }
    .roadmap-sub-title-2{
        margin: 3rem auto 2rem auto;
    }
}

@media screen  and (max-width:525px){
    .roadmap{
        padding:1rem 0 1rem 0;
        margin-top: 4rem;
    }
    .roadmap-sub-title{
        margin: 0 auto 1rem auto;
    }
    .roadmap-sub-title-2{
        width: fit-content;
        margin: 3rem auto 1rem auto;
    }
    .roadmap-title{
   
        margin-top: 4rem;
    }
    .roadmap-inner{
        margin-top: 1rem;
        max-width: 94vw;
    }
    .roadmap-goal{
        padding: 7px;
    }
    .roadmap-bottom-img{
        width: 270px;
        height: 386px;
        margin-bottom: 1rem;
    }
    .roadmap-top-text{
        width: fit-content;
        margin: 1rem auto 0 auto;
    }
    .roadmap-bottom-phase{
        margin-top: 0rem;
    }
}