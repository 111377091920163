$gray:#1E1E1E;
$black:#000000;
$blue:rgba(42,51,106,255);
$light-green:#AFFF00;
$pink:#ed07c1;

.contact-section{
    width: 100vw;
    position: relative;
    margin-top: 8rem;
    // height: 570px;
    z-index: 5;
    // background-color:$gray;
}
.contact-section-inner{
    display: flex;
    justify-content: space-between;
    height:100%;
    width: 1115px;
    max-width: 90vw;
    margin:auto;

    border-radius: 10px;
    // background-color: red;
    // overflow: hidden;
}

.contact-section-image-wrapper{
    position: relative;
    // top:-30px;
    display: grid;
    align-items: flex-end;
    left:-20px;
    // width:757px;
    // background-color: blue;
    margin: 0px; 
}

.contact-section-image{
    height: 620px;
    transform: scale3d(0,0,0);
    transition: all 300ms ease-in-out;
}
.contact-section-left{
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    width:545px;
    height: 100%;
    // background-color: $gray;
    margin: 0px;
    input{
        padding-left: 15px;
        font-size: 0.9rem;
        font-family: 'Agrandir-Variable',sans-serif;
        box-sizing: border-box;
        background-color: $blue;
        width: 100%;
        height: 48px;
        border:none;
        margin:0;
        color:white;
        margin-bottom: 1.4rem;
        &::placeholder{
            color:#737cb7;
        }
        outline: none;
    }
    textarea{
        box-sizing: border-box;
        padding: 15px;
        background-color: $blue;
        width: 100%;
        height: 146px;
        border:none;
        color:white;
        font-size:0.9rem;
        font-family: 'Agrandir-Variable',sans-serif;
        resize: none;
        &::placeholder{
            color:#737cb7;
        }
        outline: none;

    }
}
.contact-title{
    margin-bottom: 2rem;
}
.contact-title-holder{
    width: 70%;
    margin-bottom: 1rem;
}
.contact-btn-wrapper{
    width:70%;
    margin-bottom: 2rem;
}
.contact-section-submit{
    height: 40px;
    width: 169px;
    background-color: $pink;
    border:none;
    cursor: pointer;
    margin-top: 1.5rem;
    color:white;
    font-family: 'Lato',sans-serif;
    font-weight: bold;
}

.contact-download-app-wrapper{
    display: flex;
    width: 380px;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 3.5rem;
    img{
        cursor: pointer;
    }
}
.download-txt{
    margin-top: 1.5rem;
}

@media  screen and (max-width:1040px) {
    .contact-section{
        margin-top: 8rem;
        height: fit-content;
    }    
    .contact-section-inner{
        height:100%;
        display: flex;
        flex-direction: column;
        width: 500px;
    }
    .contact-section-image-wrapper{
        width: 100%;
        height: 35%;
    }
    .contact-section-image{
        height: 100%;
        width: 100%;
    }
    .contact-section-left{
        width:100%;
        height: 65%; 
    }
    .contact-section-image-wrapper{
      
        left:unset;
  
    }
}
@media  screen and (max-width:555px) {
    .contact-section{
        margin-top: 4rem;
        height: fit-content;
    }    
    .contact-section-inner{
        border-radius: 0px;
        height:100%;
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
    .contact-section-left{
        width:100%;
        height: 65%; 
        margin-bottom: 2rem
    }
    .contact-title{
        margin-bottom: 1rem;
    }
    .contact-section-image-wrapper{
        height: calc(90vw / 0.904);
        // background-color: blue;
    }
    
    .contact-section-image{
        height:unset;
        width: 90vw;
    }


    .contact-download-app-wrapper{
        display: flex;
        width: 90vw;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;
        img{
            width: 48%;
        }
    }
    .download-txt{
        margin-top: unset;
    }
    
}