.partner-section{
    width: 100vw;
    position: relative;
    margin-top: 8rem;
}
.partner-section-inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    width: 1155px;
    max-width: 90vw;
    // background-color: red;
    margin-top: 3rem
}
.partner-logo-wrapper{

    // opacity: 0;
    // position: relative;
    // top:300px;

    display: flex;
    justify-content: center;
    width: 170px;
    flex-direction: column;
    align-items: center;
    // width: 150px;
    // background-color: blue;
    img{
        width: 100%;
    }
    span{
        margin-top: 0.7rem;
    }
}

#partner-1{
    transition: top 700ms ease, opacity 700ms ease,transform 700ms ease;
}
#partner-2{
    transition: top 900ms ease, opacity 900ms ease,transform 900ms ease;
}
#partner-3{
    transition: top 1.1s ease, opacity 1.1s ease,transform 1.1s ease;
}
#partner-4{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    transition: top 1.3s ease, opacity 1.3s ease,transform 1.3s ease;
}
#partner-5{
    transition: top 1.5s ease, opacity 1.5s ease,transform 1.5s ease;
    // img{
    //     background:rgb(81, 199, 241);
    //     border-radius:50%;
    // }
}

// #partner-6{
//     transition: top 1.5s ease, opacity 1.5s ease,transform 1.5s ease;
// }
#partner-gas-logo{
    position: relative;
    right:-15px
}
@media screen and (max-width:1000px) {
    .partner-section-inner{
        // flex-direction: column;
        flex-wrap: wrap;
        margin-top: 0rem;
        justify-content: space-evenly;
    }
    .partner-logo-wrapper{
        margin-top:3rem;
    }
}
@media screen and (max-width:525px) {
    .partner-section{
        margin-top: 4rem;
    }
    .partner-logo-wrapper{
        margin-top:2rem;
        margin: 2rem 1rem 0 1rem;
        width: 130px;
    }
}