$blue:rgb(81, 199, 241);

.header{
    position: fixed;
    top:0;
    width: 100vw;
    // backdrop-filter: blur(5px);
    z-index: 1000;
    // background-color: rgba(0,0,0,0.7);
    height: 140px;
    transition: height 300ms ease-in-out, background 300ms ease-in-out;
}
.header-inner{

    display: flex;
    justify-content: space-between;
    width: 1155px;
    max-width: 90vw;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    margin: auto;
}
.header-left{
    display: flex;
    height: 100%;
}
.header-links{
    display: flex;
    align-items: center;
}
.header-right{
    display: flex;
    align-items: center;
    height: 100%;
}
.header-logo{
    height: 100%;
}
.header-link{
    margin: 0 0 0 30px;
    &:hover{
        color:$blue;
    }
}
.header-social{
    margin: 4px 0 0 50px;
    display: flex;
    align-items: center;
}
.discord-icn{
    width: 20px;
    height: 20px;
    margin: 0 10px 0 ;
}
.twitter-icn{
    width: 20px;
    height: 20px;
    margin: 0 10px 0 ;
}
.medium-icn{
    width: 35px;
}
.opensea-icn{
    width: 20px;
    margin: 0 10px 0 ;
}

.header-connect{
    width: 145px;
    height: 34px;
    // border-radius: 6px;
    border: 1px solid white;
    overflow: hidden;
    margin: 0 0px 0 20px;

    &:hover{
        background-color: $blue;
        border: 1px solid transparent;
    }
}


.header-hamburger{
    margin: 0 0px 0 20px;
    z-index: 20;
    display: none;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}
.header-hamburger-inner{
    display: flex;
    justify-content: center;
    position: relative;
    height: 40px;
    width: 40px;
}

.hamburger-top,.hamburger-bottom{
    height: 2px ;
    width: 60%;
    background-color: white;
    border-radius: 1px ;
}
.hamburger-top{
    position: absolute;
    top:14px;
}
.hamburger-bottom{
    position: absolute;
    bottom:14px;
}

.header-mobile-nav{

    display: none;
    z-index: 15;
    top:0;
    left:0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.518);
    backdrop-filter:blur(25px) !important;
    overflow: scroll;
    opacity: 0;
    transition:all 350ms ease-in-out;
}
.header-mobile-nav-inner{
    margin:20px auto 0 auto;
    display: flex;
    flex-direction: column;
    // width: 600px;
    width: 86vw;
    // background-color: green;
}
.header-mobile-logo{ 
    margin: auto;
    margin-bottom: 1rem;
    // height: 100px;
    width:100px ;
}
.mobile-nav-button{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 39px;
    border-bottom: 1px solid #7A7570;
    img{
        height: 18px;
    }
}
.header-social-wrapper{
    display: flex;
    align-items: center;
    margin-left: 30px;

}
.header-social-btn{
    img{
        height: 27px;
        width: 27px;
    }
    margin-left: 25px;
}
#header-linkedin{
    height: 22px;
    width: 22px;
}
#header-linktree{
    height: 37px;
    width: 37px;
}
@media screen and (max-width:1220px){

    .header{
        backdrop-filter: unset;
    }
    .header-links{
        display: none;
    }
    .header-social{
        display: none;
    }
    .header-hamburger{
        display: flex;
    }
    .header-mobile-logo{
        display: block;
      
    }
}

@media screen and (max-width:750px){
    .header-inner{
        height: 70px;
        padding-top: 5px;
        padding-bottom:5px;
    }
}

@media screen and (max-width:500px){
    .header-logo{
        height: 90%;
    }

}