.welcome-page{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.welcome-logo{
    position: absolute;
    height: 120px;
    left:0;
    right:0;
    margin: auto;
}

@keyframes welcome-animation {
    0%{
        opacity: 0;
        top:200px
    }
    100%{
        opacity: 1;
        top:0;
    }
}
.welcome-page-inner{
    position: relative;
    max-width: 100vw;
    width:  calc(100vh / 0.5625);
    margin:auto;
    animation: welcome-animation 550ms ease-in-out;
}
.welcome-page-img-wrapper{
    position: relative;
    width:  calc(100vh / 0.5625);
    height: 100vh ;
    max-width: 100vw;
}
.welcome-img{
    height: 100%;
    width: 100%;
}
.welcome-video{
    height: 100%;
    width: 100%;
}

.welcome-page-enter-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    bottom:5vh;
    left:0;
    right: 0;
    margin: auto;
    width:360px;
    height: calc(360px * 0.7115);
    // padding: 2rem;
    box-sizing: border-box;
    // background-color: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    p{
        font-size: 1.8rem;
        z-index: 1;
    }
    animation: welcome-animation 2500ms linear infinite;

}
@keyframes welcome-animation {
    0%{
        box-shadow:0px 0px 13px 2px #ed07c1;
    }
    50%{
        box-shadow:0px 0px 10px 2px rgb(81, 199, 241);
    }
    100%{
        box-shadow:0px 0px 13px 2px #ed07c1;
    }
}

.welcome-page-enter-wrapper-background{
    top:0px;
    position: absolute;

    width:360px;
    height: calc(360px * 0.7115);
    // background-color: blue;
    background-color: rgba(0, 0, 0, 0.3);

    border-radius: 15px;
      
}
.welcome-page-border{
    position: absolute;
    mix-blend-mode:screen;

    height: calc(360px * 0.7115);
    width:360px;
}

.welcome-btn{
    position: relative;
    // margin: 2rem 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 55px;

    border-radius: 10px;
    color:black;
    cursor: pointer;
    background-color: rgb(81, 199, 241);
    animation: btn-animation 2300ms linear infinite;
    // transition:background-color 200ms ease-in-out, color 200ms ease-in-out;

    &:hover{
        background-color: white  !important;
     
    }
}

// @keyframes btn-animation {
//     0%{
//         background-color: #f89b0f;
//     }
//     50%{
//         background-color: #faad3a;
//     }
//     100%{
//         background-color: #f89b0f;
//     }
// }

.welcome-social-wrapper{
    display: flex;
    align-items: center;
    // background-color: red;
    margin: 0.1rem 0 0.5rem 0;
    z-index: 1;
}
.welcome-social-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    margin: 0.5rem;
    cursor: pointer;
    // img{
    //     // height: 90%;
    // }
    border-radius: 50%;
    // background-color: white;
}



.welcome-social-wrapper-border{
    width: 67px;
    height: 2px;
    background-color: white;
    margin: 1rem 0  1rem 0;
    animation: welcome-animation 2500ms linear infinite;

}

.welcome-page-bottom-animation{
    display: none;
    position: absolute;
    width: 170vw;
    height: 100px;
    bottom:-20px;
}

.top-section-audio{
    position: absolute;
    bottom: 10px;
    right: 6px;
    cursor: pointer;
    z-index: 10;
}
#top-section-playing{
    height: 35px !important;
}
@media screen and (max-width:calc(100vh / 0.667)){
    .welcome-page-inner{
        height: calc(100vw * 0.667);
    }
  
    .welcome-img{
        height: calc(100vw * 0.667);
        width: unset;
    }
}
@media screen and (max-width:700px){
    // .welcome-page-img-wrapper{
    //     margin-top: 3rem;
    // }
    .welcome-page-enter-wrapper{
        position: relative;
        bottom:unset;
        top:1.1rem;
    }

    .welcome-page-enter-wrapper{
        
        p{
            font-size: 1.9rem;
        }
    }

    .welcome-page-bottom-animation{
        display: flex;
       
    }
    .welcome-page-enter-wrapper{
  
        p{
            font-size: 1.3rem;
        }
    
    }
    .top-section-audio{
    
        bottom: 85px;
        
    }
    .welcome-page{
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
    }
}