.Home{
    position: relative;
    animation: home-animation 1150ms ease-in-out;
}

@keyframes home-animation {
    0%{
        opacity: 0;

    }
    100%{
        opacity: 1;
    
    }
}