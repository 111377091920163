.genesis-section{
    position: relative;
    z-index: 5  ;
    width: 100vw;
}
.genesis-title{
    width: fit-content;
    margin-bottom: 3rem;
}
.genesis-perk-title{
    margin: auto;
    text-align: center;
    width: fit-content;
    margin-top: 0rem;
}
.genesis-inner{
    width: 1155px;
    max-width: 90vw;
    margin: auto;
    margin-top: 3rem;
}
.genesis-top{
    display: flex;
}
.genesis-img{
    position: relative;
    right:300px;
    opacity: 0;
    
    transition: all 300ms ease-in-out;

    flex-shrink: 0;
    width: 370px;
    img{
        width: 100%;
        border-radius: 13px;
        border:2px solid white
    }
    
}
.genesis-text{
    position: relative;
    top:300px;
    opacity: 0;
    transition: all 300ms ease-in-out;
    p{
        text-align: justify;
        margin: 0 0 2.5rem 0;
    }
    margin: 1rem 4rem 0 0;
    a{
        color:white
    }
}
.genesis-bottom{
    position: relative;
    margin-top: 3rem;
    width: 100.5%;
    // background-color: red;
    display: flex;
    // align-items: center;    
    // height: fit-content;
    justify-content: space-between;
    // flex-direction: column;
    // background-color: red;
}
.genesis-video{
    position: relative;
    opacity: 0;
    transform: scale3d(0,0,0);
    transition: all 300ms ease-in-out;
    top:300px;
    left:-400px;
    width: 490px;
    height: 275px;
    // width: 649px;
    // height: 365px;
    border-radius: 13px;
    // margin-bottom: 1rem;
}
.genesis-bottom-text{
    transform: scale3d(0,0,0);
    transition: all 300ms ease-in-out;
    width: 649px;
    text-align: justify;
    // margin-bottom: 1.5rem;
}
.indented-section{
    display: flex;
    margin-bottom: 0.5rem;
    :nth-child(1){
        margin-right: 5px;
    }
}
.blue-btn {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 0.9rem;
    color: black;
    width: 220px;
    height: 50px;
    background-color: rgb(81, 199, 241);
    border-radius: 10px;
    background-color: #51c7f1;
  
    &:hover {
        background-color: white;
    }
  
    box-shadow: 0px 0px 5px black;
    position: absolute;
    bottom: 14px;
    left: 141px;
}
@media screen  and (max-width:1250px){
    .genesis-bottom{
        flex-direction: column;
        width: 570px;
        max-width: 90vw;
        margin: 3rem auto 0 auto;
    }
    .genesis-video{
        width: 100%;
        height: unset;
        margin-bottom: 1rem;
    }
    .genesis-bottom-text{
        width: 100%;
    }
    .blue-btn {
       
        position: relative;
        bottom:unset;
        margin-top: 1rem;
        left: unset;
    }
}

@media screen  and (max-width:1140px){
    .genesis-title{
        margin-bottom: 2rem;
    }
    .genesis-text{
        p{
            margin: 0 0 2rem 0;
        }
        
        margin: 0rem 3rem 0 0;
    }
}

@media screen  and (max-width:1020px){
    .genesis-inner{
        width: 705px;
    }
    .genesis-top{
        flex-direction: column-reverse;
    }
    .genesis-img{
        display: none;
    }
    .genesis-text{
        margin: unset;
    }
}

@media screen  and (max-width:525px){
    .genesis-img{
        display: block;
        width: 90vw;
    }
    .genesis-title{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .genesis-text{
        p{
            margin: 0 0 1rem 0;
        }
    }
    .genesis-perk-title{
        margin-top: 2.5rem;
    }
    .genesis-bottom{
        margin:  2.5rem auto 0 auto;
    }
}
