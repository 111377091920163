
.container {
    position: relative;
    height: 550px;
    width: 550px;
    left:-15px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: blue;
}
.box {
    // border: 2px solid red;
    height: 500px;
    width: 500px;
    transform: perspective(unset) rotateX(10deg) rotateY(0deg) !important ;
    img{
        width:  100%;
        height: 100%;
        border-radius: 20px;
        border: 3px solid#ed07c1;

    }
    position: absolute;
    transition:all 50ms ease-in;
    
}

@media screen and (max-width:1200px){
    .container {
        height: 500px;
        width: 500px;
    }
    .box {
        height: 454px;
        width: 454px;
    }
}
@media screen and (max-width:1100px){
    .container {
        height: 450px;
        width: 450px;
    }
    .box {
        height: 409px;
        width: 409px;
    }
}

@media screen  and (max-width:1020px){
    .container {
        display: none;
    }
}