@import url("https://use.typekit.net/tcp8tbf.css");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
.App {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  animation: emerge 1s ease-in-out;
  // width: 100vw;
  // height: fit-content;
  overflow-x: hidden;
  // overflow-y: scroll;
  background: rgb(14, 70, 131,0);
  text-shadow: 0px 0px 5px black;

}
// ::-webkit-scrollbar{
//   z-index: 21;
// }

@keyframes emerge {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// @font-face {
//   font-family: edo;
//   font-style: normal;
//   font-weight: 400;
//   src: local('Edo'), url(https://fonts.cdnfonts.com/s/136/edo.woff) format('woff')
// }
@font-face {
  font-family: 'Edo';
  src: url(./fonts/edo.ttf);
}

.ffam-lato {
  font-family: lato, sans-serif;
}

.ffam-edo {
  font-family: 'Edo';
  // font-family: 'Open Sans';

}

//////\\\\\\
//font-size
//////\\\\\\
.fs-xl {
  font-size: 3.8rem;
}

.fs-xlm {
  font-size: 3.5rem;
}

.fs-xls {
  font-size: 2.65rem;
}
.fs-l {
  // font-size: 1.65rem;
  font-size: 1.55rem;

}

.fs-team-title {
  font-size: 2rem;
}

.fs-m {
  font-size: 1.2rem
}

.fs-ms {
  font-size: 1.2em
}

.fs-s {
  font-size: 1.05rem;
  font-weight: 400 !important;

}

.fs-xs {
  font-size: 0.95rem;
  font-weight: 400 !important;
}

.fs-xxs {
  font-size: 0.87rem;
}


@media screen  and (max-width:1400px){
  .fs-xl {
    font-size: 3rem;
  }
  .fs-l {
    // font-size: 1.65rem;
    font-size: 1.25rem;
  
  }
}
@media screen  and (max-width:600px){
  .fs-xl {
    font-size: 3rem;
  }
  .fs-xlm {
    font-size: 2.8rem;
  }
  .fs-l {
    font-size: 1.45rem;
  }
  .fs-xls {
    font-size: 2.45rem;
  }
  .fs-m {
    font-size: 1.1rem
  }
}
@media screen  and (max-width:500px){
  .fs-xl {
    font-size: 2.5rem;
  }
  .fs-xlm {
    font-size: 2.3rem;
  }
  .fs-xs{
    font-size: 0.85rem;
  }

  .fs-l {
    font-size: 1.17rem;
  }
  .fs-xls {
    font-size: 1.96rem;
  }
  .fs-s{
    font-size: 0.87rem;
  }
  .fs-m {
    font-size: 1rem
  }
}
//////\\\\\\
//font-color
//////\\\\\\
.tc-white {
  color: #ffffff;
}
.tc-blue{
  color:rgb(81, 199, 241)
}
.tc-pink{
  color:#ed07c1;
}
.tc-gray{
  color:#999999;
}


//font-weight
.fw-bold {
  font-weight: bold;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-extra-bold {
  font-weight: 800;
}

.fw-black {
  font-weight: 900;
}

.fw-normal {
  font-weight: 400;
}

.fw-light {
  font-weight: 300;
}

.app-bg {
  // top:0;
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/images/main.webp);
  background-position: center top;
  background-size: cover;


  transform: scale3d(1.7,1.7,1.7);
}

// .toast>div{
  
// }
.Toastify__toast{
  text-shadow: none !important;
  background-color: rgba(0,0,0,0.8) !important;
  border:2px solid white;
  // box-shadow: 0px 0px 5px 4px white;
  border-radius: 12px !important;
  color:white !important;
  svg{
    color:white !important;
  }
  a{
    color:white !important;
  }
  // font-family: 'Lato',sans-serif !important;
  // font-size: 15px !important;
 
}

.indented-section{
  display: flex;
  :nth-child(1){
      margin-right: 5px;
  }
}
.verified-contract-address{
  max-width: 90vw;
  position: relative;
  display: flex;
  margin: auto;
  width: fit-content;
  margin-top: 8rem;
  text-align: center;
  button{
    text-decoration: underline;
  }
  @media screen and (max-width:1100px){
    flex-direction: column;
  }
  @media screen and (max-width:500px){

    margin-top: 5rem;
  }
}
